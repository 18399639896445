import { PageProps } from 'gatsby'
import { useGetCourseDetails } from 'hooks/useGetCourseDetails'
import React, { useEffect, useState } from 'react'
import { getExternal, getSubmissionExternal } from '../../../../../API/getExternal'
const ReactQuill = typeof window !== 'undefined' ? require('react-quill') : () => <></>
import 'quill/dist/quill.snow.css'
import 'styles/index.scss'
import { Button } from 'react-bootstrap'
import { UserType } from 'types/UserAdditionMetaData'
import { NonUndefined, PromiseType } from 'utility-types'
export type LinkType = {
	link: string
	name: string
	description: string
	doc: boolean
}

const ResourceView: React.FC<PageProps> = ({ params: { id, external_id } }) => {
	const { ShowDetails, viewAs } = useGetCourseDetails(id)
	const [asset, setAsset] = useState<LinkType>()
	const [assetSubmission, setAssetSubmission] = useState<NonUndefined<PromiseType<NonUndefined<ReturnType<typeof getSubmissionExternal>>>>>([])
	useEffect(() => {
		const run = async () => {
			const asset = await getExternal(external_id, viewAs)

			if (asset) {
				setAsset(asset)
			}
		}
		run()
	}, [external_id, viewAs])
	useEffect(() => {
		const run = async () => {
			if (viewAs === 'Teacher') {
				const submission = await getSubmissionExternal(external_id, id)
				if (submission) {
					setAssetSubmission(submission)
					console.log(submission)
				}
			}
		}
		run()
	}, [external_id, viewAs])
	return (
		<>
			{ShowDetails}
			{asset && (
				<>
					<h2 className='pt-2'>{asset.name}</h2>
					<ReactQuill
						theme='snow'
						value={asset.description}
						readOnly={true}
						modules={{
							toolbar: false,
						}}
					/>
					{asset.link && (
						<>
							<a href={asset.link} target='_blank' rel='noopener noreferrer'>
								<Button className='open-button'>Open</Button>
							</a>
						</>
					)}
				</>
			)}
			{asset && viewAs === 'Teacher' && (
				<div>
					<div className='row mt-5'>
						<div className='col-6'>
							<span>
								<h4>Viewed Student</h4>
							</span>
							<table className='table'>
								<thead>
									<tr>
										<th scope='col'>Index</th>
										<th scope='col'>Name</th>
										<th scope='col'>UserName</th>
										<th scope='col'>Time</th>
									</tr>
								</thead>
								<tbody>
									{assetSubmission
										.filter((assetSubmission) => assetSubmission.isOpened === true)
										.map((elem, index) => {
											return (
												<>
													<tr>
														<th scope='row'>{index + 1}</th>
														<td>
															{elem.first_name} {elem.last_name}
														</td>
														<td>{elem.username}</td>
														<td>{new Date(elem.last_open_date).toLocaleString()}</td>
													</tr>
												</>
											)
										})}
								</tbody>
							</table>
						</div>
						<div className='col-6'>
							<span>
								<h4>Not Viewed</h4>
							</span>
							<table className='table'>
								<thead>
									<tr>
										<th scope='col'>Index</th>
										<th scope='col'>Name</th>
										<th scope='col'>Username</th>
									</tr>
								</thead>
								{assetSubmission
									.filter((assetSubmission) => assetSubmission.isOpened == false)
									.map((elem, index) => {
										return (
											<>
												<tr>
													<th scope='row'>{index + 1}</th>
													<td>
														{elem.first_name} {elem.last_name}
													</td>
													<td>{elem.username}</td>
												</tr>
											</>
										)
									})}
							</table>
						</div>
					</div>
				</div>
			)}
		</>
	)
}

export default ResourceView
